import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import MainArea from '../components/MainArea/MainArea';


const Home = ({socket}) => {
    
    return(
    <div >
     <MainArea  />
    </div>
    )
}
export default Home;