import React, { Component } from "react";

class Optin extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="optin">
        
      </div>
    );
  }
}

export default Optin;